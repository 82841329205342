.footerConteiner {
  bottom: 0;
  width: 100%;
  height: 40px;
  color: white;
  position: fixed;
  background-color: #0E0C5A;
}

.footerText {
  display: flex;
  align-items: center;
  justify-content: space-around;
} 

body {
  margin-bottom: 100px;
}