body {
  background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
}

.col-centered {
  display: block;
  margin-left: auto;
  text-align: center;
  margin-right: auto;
}

.loader {
  top: 50%;
  left: 50%;
  right: 50%;
  width: 80px;
  height: 80px;
  z-index: 99999;
  position: absolute;
  border-radius: 100%;
  border: 10px solid #f3f3f3;
  border-top: 10px solid #3498db;
  animation: spin 2s linear infinite;
}
.contenLoading {
  top: 0px;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999999;
  background: #2a364e7a;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
