.btn-primary {
  background-color: #2e2bab;
  border-color: #2e2bab;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
  background-color: #0500b0 !important;
  border-color: #0500b0 !important;
}

.MuiInputAdornment-positionEnd {
  background: #2e2bab !important;
  height: 200px !important;
}

.MuiInputBase-inputAdornedEnd {
  background: white;
}
