.MuiDataGrid-columnsContainer {
  /* background: #7d68db !important; */
}

.MuiDataGrid-iconSeparator {
  color: #0e0c5a !important;
}

.MuiDataGrid-root {
  border: none !important;
}

.MuiDataGrid-checkboxInput {
  background: white !important;
  border-radius: inherit !important;
  padding: 1px !important;
}
.MuiDataGrid-row:nth-child(even){
  background-color: #F1F2F2;
}