.head {
  width: 100%; /* Establecemos que el header abarque el 100% del documento */
  display: flex;
  overflow: hidden; /* Eliminamos errores de float */
  margin-bottom: 20px;
  background: #0e0c5a;
  justify-content: space-around;
}

.imagen {
  background-image: "../../Assets/avatar2.svg";
  width: "80%";
  margin-top: "30%";
  border-radius: "56px";
  background-color: "white";
}
