.titleHeadPDP {
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  color: white;
  height: 40px;
  background: rgb(14, 12, 90);
  margin: 0px !important;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 36px;
}

.cardBodyPDP {
  width: 100%;
  margin: 0px;
  padding: 0px !important;
  width: "100%";
  text-align: "left";
  height: "500px";
}

.contenImgPDP {
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  text-align: center;
  padding: 16%;
  margin-top: 20%;
}

.contendContac {
  bottom: 0px;
  font-size: 11px;
  position: absolute;
  text-align: justify;
  background: #f1f2f2;
  padding: 1px;
  left: 1px;
  /* padding: 12px 12px 12px 12px; */
}
.bodyCard {
  width: 70%;
  left: 4.5%;
}

.position {
  padding: 0px;
  display: flex;
  flex-direction: row-reverse;
}

.positionControls {
  padding: 0px;
  display: flex;
  flex-direction: row;
}
