.text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 20; 
  -webkit-box-orient: vertical; }

  .box{
    box-sizing: border-box;
    border: 3px solid transparent;
    background-clip:padding-box;
  }
